<template>
  <div class="client-info" v-if="loggedIn" @click="collapsed = !collapsed">
    <div><strong>Censhare Studio Login Information</strong></div>
    <div v-if="!collapsed"><strong>User:</strong> <br>{{ formatUser }}</div>
    <div v-if="!collapsed"><strong>JobFlowClient:</strong> <br>{{ formatJobFlowClient }}</div>
    <div v-if="!collapsed"><strong>CenshareClient:</strong> <br>{{ formatCenshareClient }}</div>
  </div>
</template>

<script>
export default {
  name: "DebugClient",
  data() {
    return {
      collapsed: true
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.loggedIn === true;
    },
    formatUser () {
      return `${this.$store.state.auth.user.first_name} ${this.$store.state.auth.user.last_name} [ID: ${this.$store.state.auth.user.id}]`
    },
    formatJobFlowClient () {
      return `${this.$store.state.auth.jobFlowClient.url} [ID: ${this.$store.state.auth.jobFlowClient.id}]`
    },
    formatCenshareClient () {
      if (this.$store.state.auth.censhareClient.url === null) {
        return `Not defined [ID: ${this.$store.state.auth.censhareClient.id}]`
      }
      return `${this.$store.state.auth.censhareClient.url} [ID: ${this.$store.state.auth.censhareClient.id}]`
    }
  }
}
</script>

<style lang="scss" scoped>

.client-info {
  z-index: 10;
  position: fixed;
  bottom: 50px;
  left: 0;
  padding: 10px;
  color: #333333;
  font-size: 12px;
  text-align: left;
  margin: 0 auto;

  div {
    margin-bottom: 10px;
  }
}
</style>