<template>
  <div id="app">
    <div v-if="isLoggedIn">
      <Debug v-if="debugMode"></Debug>
      <SessionTimer />
      <div class="wrapper">
        <nav class="navbar navbar-expand">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item">
              <router-link to="/settings" class="nav-link btn" :style="clientColor">Settings</router-link>
            </li>
            <li class="nav-item" v-if="isCenshareUrlSet">
              <router-link to="/schema-manager" class="nav-link btn" :style="clientColor">Schema Manager</router-link>
            </li>
            <li class="nav-item" v-if="isCenshareUrlSet">
              <router-link to="/entity-viewer" class="nav-link btn" :style="clientColor">Entity Viewer</router-link>
            </li>
          </ul>
        </nav>
        <router-view />
      </div>
    </div>
    <div v-else class="cenflow-logo">
      <span class="helper"></span>
    </div>
  </div>
</template>

<script>
import SweetAlertService from "@/services/sweetalert";
import Debug from "@/components/Debug";
import SessionTimer from "@/components/SessionTimer";


export default {
  name: 'App',
  components: { Debug, SessionTimer },
  data() {
    return {
      debugMode: false,
      message: null,
      // loader: null,
      client: {
        id: null,
        name: null,
        url: null
      }
    }
  },
  computed: {
    isLoggedIn() {
      console.log('LoggedIn', this.$store.state.auth.loggedIn === true)
      return this.$store.state.auth.loggedIn === true;
    },
    isCenshareUrlSet() {
      return typeof this.$store.state.auth.censhareClient.url !== 'undefined'
        && this.$store.state.auth.censhareClient.url !== null;
    },
    clientColor() {
      const { background_color, text_color } = this.$store.state.auth.jobFlowClient ?? {};

      if (!background_color) {
        return {}; // Return an empty object if background_color is not available
      }

      return {
        'backgroundColor': background_color,
        'color': text_color,
        '--client-color-hover': `rgba(${this.extractRGB(background_color)}, 0.6)`
      };
    },
  },
  created() {
    if (window.self === window.top) {
      return; // if w.self === w.top, we are not in an iframe
    }
    // send message to parent about height updates
    this.send_height_to_parent_function(); //whenever the page is loaded
    this.resizeItNowPrivate();
  },
  mounted() {
    this.jwtLogin()
    window.addEventListener("resize", this.send_height_to_parent_function); // whenever the page is resized
    window.addEventListener("readystatechange", this.send_height_to_parent_function); // whenever the page is resized
    var observer = new MutationObserver(this.send_height_to_parent_function); // whenever DOM changes PT1
    var config = { attributes: true, childList: true, characterData: true, subtree: true }; // PT2
    observer.observe(window.document, config); // PT3
  },
  methods: {
    async jwtLogin() {
      /* this.loader = this.$loading.show({
         container: null,
         canCancel: false,
         loader: 'dots',
         opacity: 0
       }); */

      this.$store.dispatch("auth/login").then(
        () => {
          console.log('User logged in')
          // this.loader.hide()
        },
        (err) => {
          // this.loader.hide()
          this.$swal(SweetAlertService.error(err))
        }
      );
    },
    send_height_to_parent_function() {
      var element = document.querySelector("body");
      var height = element.offsetHeight;
      // console.log("Sending height as " + height + "px");
      parent.postMessage({ "height": height }, "*");
    },
    resizeIframe(iframe) {
      this.resizeItNowPrivate(iframe);
    },

    resizeIframeOnload() {
      let iframe = document.getElementById('healthy_behavior_iframe');
      iframe.onload = () => {
        this.resizeItNowPrivate(iframe);
      }
    },

    resizeItNowPrivate() {
      let iframe = document.getElementById('healthy_behavior_iframe');
      if (!iframe) return;
      // console.log("there's an iFrame")
      var body = iframe.contentWindow.document,
        html = iframe.contentWindow.document.documentElement;
      var height = Math.max(body.scrollHeight ?? 0, body.offsetHeight ?? 0,
        html.clientHeight ?? 0, html.scrollHeight ?? 0, html.offsetHeight ?? 0);
      if (height < 600) {
        height = 600;
      }
      iframe.style.height = height + 'px';
    },
    extractRGB(color) {
      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      return `${r}, ${g}, ${b}`;
    },
  }
}
</script>

<style lang="scss">


#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin: 20px;
  overflow: hidden;
}

body {
  background: #f6f6f6;
  overflow: hidden;
}

.wrapper {
  padding: 25px;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

nav {
  display: block;

  .nav-link {
    box-shadow: 1px 1px 3px rgb(97 94 94 / 25%);
    padding: 10px 20px;
    display: inline-block;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    margin-right: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover,
    &:active,
    &:focus {
      outline: none !important;
      color: #000 !important;
      background-color: var(--client-color-hover) !important;
      
    }

    &.router-link-active {
      background: var(--client-color-hover) !important;;
    }
  }
}

.buttons {
  clear: both;
  text-align: right;
}



.cenflow-logo {
  height: 100%;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;

  text-align: center;
  margin: -60px 0 1em;

  .helper {
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
    overflow: hidden;
  }

  img {
    vertical-align: middle;
  }
}

.form-control {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 700;
  color: #333;
  background: #fff;
  padding: .75rem .75rem;
  border: none;
  width: 100%;
  box-shadow: 1px 1px 3px rgba(97, 94, 94, 0.25);
  transition: background-color .2s, color .2s, border-color .2s, box-shadow .2s;
  appearance: none;
  border-radius: 2px
}

/* */</style>
