import axios from 'axios';
import { API_URL } from './api_url';
import authHeader from "@/services/auth-header";

class SettingsService {
    post(settings) {
        return axios
            .post(API_URL + 'settings', settings, {headers: authHeader()})
            .then(response => {
                return response.data;
            });
    }
}

export default new SettingsService();